<template>
  <div class="container">
    <div class="header">
      <div class="logo">
<!--        <img src="@/assets/clogo.png">-->
        <div class="title" style="color: #05586a; font-size: 30px; text-align: center;margin-left: 260px;">AISecurity</div>
      </div>

      <div class="returnindex" style="margin-left: 800px;">
        <img src="@/assets/return8.png">

          <div style="color: #05586a;font-size: 20px;" @click="junk()">AISecurity-基于大模型的网络空间安全智能问答系统</div>

      </div>
    </div>
    <div class="content">
<!--      <div class="left">-->
<!--&lt;!&ndash;        <button class="leftbutton1" style="background-color: #58a8f0;">清空对话</button>&ndash;&gt;-->
<!--&lt;!&ndash;        <button class="leftbutton1" style="background-color: #58a8f0;"><a href="/recommended_1">百科问答</a></button>&ndash;&gt;-->
<!--      </div>-->
      <div class="right" style="height: 1065px;">
        <div class="righttext">
<!--          <div class="rightcontent">-->
          <div>
<!--            <div class="upload-header2">-->
<!--              <el-button type="primary" @click="add()">新建知识库</el-button>-->
<!--            </div>-->
            <div class="upload-header">请选择知识库：</div>
<!--            <span>请选择或新建知识库：</span>-->

            <div class="upload-header2">
              <select @change="handleSelect" v-model="selectedItem" placeholder="请选择或新建知识库">
                <option v-for='(item, index) in selectList' :key='index' :value='item'>{{ item }}</option>
<!--                <option value="new">新建知识库</option>-->
              </select>
            </div>


            <div v-if="isShow1">
              <div class="upload-header">上传知识文件：</div>
              <div>
                <el-upload
                    class="upload-demo"
                    drag
                    :action="uploadURL"
                    :data="uploadURLData"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-success="uploadSuccess"
                    :before-remove="beforeRemove"
                    :file-list="uploadFileList"
                    accept=".xlsx, .xls, .csv, .txt,.docx,.pptx,.pdf,.md,.doc"
                    multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  </el-upload>
              </div>
              <div class="upload-header">请输入知识库介绍:</div>
<!--              <span>请输入知识库介绍:</span>-->
              <div class="upload-header2">
                <el-input
                    type="textarea"
                    placeholder="请输入知识库介绍"
                    :rows="2"
                    v-model="kbInfo"> <!-- 双向绑定 kbInfo 变量 -->
                </el-input>
              </div>
              <div class="allknowname">
                <div class="upload-header">文件处理配置:</div>
<!--                <span>文件处理配置</span>-->
                <div class="knowname">
                  <div class="knowname1">
                    <span>单段文本最大长度：</span>
                    <el-input-number v-model="chunkSize" @change="handleChange('chunk_size')" :min="1" :max="1000" label="描述文字"></el-input-number>
                  </div>
                  <div class="knowname2">
                    <span>相邻文本重合长度：</span>
                    <el-input-number v-model="chunkOverlap" @change="handleChange('chunk_overlap')" :min="0" :max="1000" label="描述文字"></el-input-number>
                  </div>
                  <div class="knowname3">
                    <el-radio v-model="zhTitleEnhance" @change="handleChange('zh_title_enhance')" label="1">开启中文标题加强</el-radio>
                  </div>
                </div>
              </div>
              <div class="btn">
                <el-button type="primary" @click="addFile">添加文件到知识库</el-button>
              </div>
              <hr />
              <div>
                <div>
                  <div class="upload-header">知识库中已有文件</div>
<!--                  <span>知识库中已有文件</span>-->
                  <el-table class="contentList" v-loading="loading" :data="contentList" style="width: 100%;" stripe highlight-current-row>
                    <el-table-column fixed type="index" label="序号"></el-table-column>
                    <el-table-column prop="fileName" label="文档名称"></el-table-column>
                    <el-table-column fixed='right' label="操作">
                      <template v-slot="scope">
                        <el-row style="text-align: left">
                          <el-col :span="8">
                            <el-button size="mini" type="danger" @click="delopen(scope.row)">删除</el-button>
                          </el-col>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
<!--            <div v-if="isShow2">-->
<!--              <div class="upload-header">新建知识库名称</div>-->
<!--&lt;!&ndash;              <span>新建知识库名称</span>&ndash;&gt;-->
<!--              <div class="upload-header3">-->
<!--              <el-input v-model="knowledgeBaseName" placeholder="新知识库名称"></el-input>-->
<!--              </div>-->
<!--              <div class="upload-header">知识库简介</div>-->
<!--&lt;!&ndash;              <span>知识库简介</span>&ndash;&gt;-->
<!--              <div class="upload-header3">-->
<!--                <el-input placeholder="知识库简介"></el-input>-->
<!--              </div>-->

<!--              <div class="allname">-->
<!--                <div class="name1">-->
<!--                  <span>向量库类型</span>-->
<!--                  <el-select v-model="selectedOption" placeholder="请选择">-->
<!--                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item"></el-option>-->
<!--                  </el-select>-->
<!--                </div>-->
<!--                <div class="name2">-->
<!--                  <span>Embedding 模型</span>-->
<!--                  <el-select v-model="selectedOption1" placeholder="请选择">-->
<!--                    <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item"></el-option>-->
<!--                  </el-select>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="upload-header4">-->
<!--                <el-button type="primary" @click="addKnowledge()">新建</el-button>-->
<!--              </div>-->

<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>

.rightcontent{
  width: 1100px;
  border: 1px solid green;
  margin-left: 153px;
}
.allknowname{
  margin-top: 20px;
}
.btn{
  margin-top: 20px;
}
hr{
  margin: 20px 0px;
}
.upload-demo{
  height: 300px;
}
.righttext span{
  font-size: 18px;
}
select{
  width: 100%;
  height: 40px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.4;
}
.knowname{
  display: flex;
  justify-content: flex-start;
}
.knowname1, .knowname2, .knowname3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowname1 .el-input-number, .knowname2 .el-input-number, .knowname3 .el-radio {
  margin-top: 10px; /* 调整 el-select 与 span 之间的间距 */
}
.allname {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.name1, .name2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name1 .el-select, .name2 .el-select {
  margin-top: 10px; /* 调整 el-select 与 span 之间的间距 */
}
.fileDeal{
  float: left;
}
.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}
.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    //min-height: 85px; /* 使用 min-height 以适应内容的高度 */
    width: 1003px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    //min-height: 321px; /* 使用 min-height 以适应内容的高度 */
    width: 1056px;
    background: #FFFFFF;
    border-radius: 20px;
  }
}
.select {
  margin-top: 187px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .selectdiv1 {
    margin-top: 60px;
    margin-left: 65px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  img {
    margin-top: 59px;
    margin-left: 155px;
    width: 40px;
    height: 31px;
  }

  .selectdiv2 {
    margin-top: 28px;
    margin-left: 31px;
    width: 192px;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    border: 1px solid #96AFFF;
    text-align: center;
    a{
      font-family: Microsoft YaHei UI;
      font-weight: 550;
      font-size: 28px;
      color: #2070CA;
      line-height: 92px;
      text-decoration: none;
    }

  }
}

.hand {
  margin-top: 51px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .handdiv1 {
    margin-left: 68px;
    margin-top: 60px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  .handdiv2 {
    margin-top: 65px;
    margin-left: 237px;
    height: 21px;
    font-family: Microsoft YaHei UI;
    font-weight: 300;
    font-size: 22px;
    color: #2070CA;
  }
}
.right {
  overflow-y: auto; /* Add vertical scroll if content overflows */
  max-height: 1065px; /* Limit height of the container */
}

.righttext {
  padding: 20px; /* Add some padding to the content */
}

.righttext > div {
  margin-bottom: 15px; /* Add spacing between child div elements */
}

.nameList {
  margin-top: 20px; /* Add spacing between the table and other content */
}

.upload-demo {
  margin-bottom: 15px; /* Add spacing below the upload component */
}



.upload-header {
  margin-bottom: 10px; /* 可以根据需要调整间距大小 */
}
.upload-header2 {
  margin-bottom: 20px; /* 可以根据需要调整间距大小 */
}
.upload-header3 {
  margin-bottom: 30px; /* 可以根据需要调整间距大小 */
}
.upload-header4 {
  margin-top: 25px; /* 可以根据需要调整间距大小 */
}



</style>
<script>
import axios from "axios";
let mymessage = {}
export default {
  name: "upload",
  created() {
    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
    this.getlist()
    // this.getKnowList()
    this.selectedOption = this.options[0].label
    this.selectedOption1 = this.options1[0].label
  },
  data(){
    return{
      kbInfo: '', // 知识库介绍
      questionInput: "", // 用户输入的问题
      textContent:{},
      loading: false, // 如果需要加载状态，可以根据实际情况添加
      questionsWithAnswers: [], // 保存用户提问及接口返回的答案
      nameList:[],
      selectList:[],
      selectedItem: '', // 用于存储选择的值
      contentList:[],
      num: 1,
      radio: '',
      isShow1: true,
      isShow2: false,
      knowledgeBaseName: '', // 新知识库名称
      selectedOption: null, // 选中的向量库类型选项
      selectedOption1: null, // 选中的 Embedding 模型选项
      options: [{ value: '选项1', label: 'faiss' }],
      options1: [{ value: '选项1', label: 'bge-large-zh' }],
      chunkSize: 250, // 初始值可根据需求修改
      chunkOverlap: 50, // 初始值可根据需求修改
      zhTitleEnhance: false, // 初始值可根据需求修改
      fileName: '', // 存储上传文件名
      knowledge_base_name: '', // 存储选择的知识库名称
      uploadFileList: [],
      uploadURL: 'http://10.99.16.24:8088/medical/' ,
      uploadURLData:{method:'upload_file',createTime:''},
      fileLinks: {}, // 存储文件名到链接的映射
      fileCon:'',
    }
  },
  methods:{
    //获取已经创建的知识库，nameList暂时是显示知识库文件，selectList放在知识库选择的select当中
    getlist(){
      var that = this
      axios.get('/api/knowledge_base/list_knowledge_bases')
          .then((res) =>{
            console.log(res)
            that.selectList = res.data.data
            that.nameList = res.data.data.map(item => ({ fileName: item }));
            console.log(that.selectList)
          })
          .catch((err) =>{
            console.log(err)
          })
    },
    //获取选择知识库下的文件
    getKnowList(encodedKnowledgeBase){
      var that = this
      const baseUrl = '/api/knowledge_base/list_files';
      const url = baseUrl + '?knowledge_base_name=' + encodedKnowledgeBase;
      axios.get(url)
          .then((res) =>{
            console.log(res)
            console.log(res.data)
            console.log(res.data.data)
            that.contentList = res.data.data.map(item => ({ fileName: item }));
            if (res.data.code == '200') {
              mymessage.success(res.data.msg)
            }
            if (res.data.code == '404') {
              mymessage.error(res.data.msg)
            }
          })
    },
    //根据请选择或新建知识库显示不同页面，如果选择已创建的知识库就显示isShow1,如果选择新建知识库就显示isShow2
    handleSelect(event) {
      const selectedOption = event.target.value;
      const encodedKnowledgeBase = encodeURI(selectedOption);
      // const encodedKnowledgeBase = URLEncoder.encode(selectedOption, "utf-8");
      console.log(encodedKnowledgeBase)
      // 调用 getKnowList 方法，并将编码后的知识库名称作为参数传递
      this.getKnowList(encodedKnowledgeBase);
      this.knowledge_base_name = event.target.value;
      if (selectedOption === 'new') {
        this.isShow1 = false;
        this.isShow2 = true;
      } else {
        this.isShow1 = true;
        this.isShow2 = false;
      }
    },
    //新建知识库
    addKnowledge(){
      const url = '/api/knowledge_base/create_knowledge_base';
      const data = {
        knowledge_base_name: this.knowledgeBaseName,
        vector_store_type: 'faiss', // 获取向量库类型选择的 label
        embed_model: 'bge-large-zh', // 获取 Embedding 模型选择的 label
      };
      axios.post(url, data)
          .then((res) =>{
            console.log(res)
            if(res.data.code == '200'){
              mymessage.success(res.data.msg)
            }
            if(res.data.code == '404'){
              mymessage.error(res.data.msg)
            }
          })
    },
    addFile() {
      const url = '/api/knowledge_base/upload_docs';
      const formData = new FormData();

      // 将上传文件列表中的文件添加到 FormData
      this.uploadFileList.forEach((file) => {
        formData.append('files', file.raw, file.name);
      });

      // 其他 POST 参数
      formData.append('knowledge_base_name', this.knowledge_base_name);
      formData.append('to_vector_store', 'true');
      formData.append('override', 'false');
      formData.append('not_refresh_vs_cache', 'false');
      formData.append('chunk_size', this.chunkSize);
      formData.append('chunk_overlap', this.chunkOverlap);
      formData.append('zh_title_enhance', this.zhTitleEnhance);

      // 可选的文档内容数据
      const customDocs = {
        "test.txt": [
          {
            "page_content": "custom doc",
            "metadata": {},
            "type": "Document"
          }
        ]
      };
      formData.append('docs', JSON.stringify(customDocs));

      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 确保使用 multipart/form-data
          'accept': 'application/json',
        },
      })


      .then((res) => {
        console.log('Response:', res); // 日志记录响应
        if (res.data.code === 200) {
          mymessage.success('文件上传与向量化完成');
        }
        if (res.data.code === 404) {
          mymessage.error('文件上传与向量化失败');
        }
      })
    },
    // addFile(){
    //   const url = 'http://183.220.38.26:8785/knowledge_base/upload_docs';
    //   const files = this.fileCon;
    //   const data = {
    //     files,
    //     knowledge_base_name: this.knowledge_base_name,
    //     override: 'false',
    //     to_vector_store: 'true',
    //     chunk_size: this.chunkSize,
    //     chunk_overlap: this.chunkOverlap,
    //     zh_title_enhance: this.zhTitleEnhance,
    //     docs: { "test.txt": [{ "page_content": "custom doc", "metadata": {}, "type": "Document" }] },
    //     not_refresh_vs_cache: 'false'
    //   };
    //   axios.post(url, data)
    //       .then((res) => {
    //         console.log(res)
    //         if (res.data.code == '200') {
    //           mymessage.success(res.data.msg)
    //         }
    //         if (res.data.code == '404') {
    //           mymessage.error(res.data.msg)
    //         }
    //       })
    // },
    handleChange(field) {
      // 根据传入的字段名更新对应的变量值
      switch (field) {
        case 'chunk_size':
          this.chunkSize = this.num;
          break;
        case 'chunk_overlap':
          this.chunkOverlap = this.num;
          break;
        case 'zh_title_enhance':
          this.zhTitleEnhance = this.radio === '1';
          break;
        default:
          break;
      }
    },
    delopen(row){
      this.$confirm('此操作将永久删除该知识库, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    del(row){
      var that = this
      console.log(row.fileName)
      const url = '/api/knowledge_base/delete_docs';
      const data = {
        knowledge_base_name: this.knowledge_base_name,
        file_names:[row.fileName],
        delete_content: 'true',
        not_refresh_vs_cache: 'false',
      };
      axios.post(url, data,)
          .then((res) =>{
            if (res.data.code == '200') {
              mymessage.success(res.data.msg)
              that.getKnowList(encodedKnowledgeBase)
            }
            if (res.data.code == '404') {
              mymessage.error(res.data.msg)
            }
          })
    },
    //上传成功时的钩子函数
    uploadSuccess(response,file,fileList){
      console.log(fileList)
      this.uploadFileList = fileList
      // 如果接口返回了文件的链接信息，将其存储到 fileLinks 中
      const fileName = file.name;
      let fileLink = response[fileName];

      if (fileLink) {
        if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
          fileLink += ";type=application/msword"; // 添加 MIME 类型
        }

        // 保存文件链接
        this.fileLinks[fileName] = fileLink;
        this.fileCon = this.fileLinks[fileName]
        console.log(this.fileLinks[fileName])
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    add() {
      this.$router.push('/selectionAssistant_1')
    },
    junk() {
      this.$router.push('/recommended_1');
    },

  }
};
</script>