<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <!--        <img src="@/assets/clogo.png">-->
        <div class="title" style="color: #05586a;font-size: 32px;">AISecurity-基于大模型的网络空间安全智能问答系统</div>
      </div>

      <!--      <div class="returnindex">-->
      <!--        <a href="/">-->
      <!--          <div style="color: #05586a;padding-right: 15px;">存健康医药大模型</div>-->
      <!--        </a>-->
      <!--        <img src="@/assets/跳转.png" style="width: 20px; height: 20px;">-->
      <!--      </div>-->
    </div>
    <div class="content">
      <div class="left" >
        <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="clearllm()">
          <img src="@/assets/刷新.png">
        </button>
        <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="junk()">设置参数</button>
        <!--        <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="llm()">大模型问答</button>-->
        <!--        <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="med()">药品说明书</button>-->
        <!--        <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="guide()">问诊指南</button>-->
        <div class="leftbottom" >
          <button class="leftbutton1" style="background: linear-gradient(to right, #19adcf, #5ce9e7);" @click="junk1()">知识库管理</button>
        </div>
      </div>
      <div class="right" style="height: 800px;">
        <!--        <div class="righttitle">医疗用药大模型</div>-->
        <div class="neirong" style="height: 670px; overflow-y: auto;">
          <!-- 循环显示问题和答案 -->
          <div v-for="(item, index) in questionsWithAnswers" :key="index" class="questionAndAnswer">
            <div class="righhtquestion">
              <img src="@/assets/question.png">
              <div class="questionText">
                {{ item.question }}
              </div>
            </div>
            <div class="rightanswer">
              <img src="@/assets/answer.png">
              <div class="rightanswercontent" v-html="item.answer">
              </div>
            </div>

            <div class="knowledgeResultTitle">知识库匹配结果:</div>
            <!--             显示额外的答案-->
            <div class="rightanswer">

              <div class="knowledgeResultContent">
                <div v-for="(aanswer, index) in item.additionalAnswers" :key="index" class="knowledgeResultItem">
                  <div v-html="aanswer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="question">
          <input type="text" placeholder="       请输入您的问题" v-model="questionInput" style="width: 100%;border: none;font-size: 20px" @keydown.enter="handleSend">
          <img src="@/assets/arrow.png" @click="handleSend()">
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>
.logo{
  display: flex; /* 使用 Flexbox 布局 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中 */
  text-align: center; /* 水平居中文字 */

  img{
    height: 48px;
  }
  .title {
    color: #05586a;
    white-space: nowrap; /* 防止文字换行 */
    margin-left: 650px;
  }
}
.leftbutton1:focus {
  outline: none;
}
.knowledgeResultTitle{
  display: flex;
  margin-top: 10px;
  margin-left: 200px;
  color: #05586a;
  font-size: 20px;
}


.knowledgeResultContent {
  display: flex; /* 将答案横向排列 */
  flex-wrap: wrap; /* 如果答案超出一行宽度，自动换行 */
}

.knowledgeResultItem {
  width:  1056px;
  margin-right: 10px; /* 答案框之间添加一些间距 */
  margin-left: 75px;
  margin-bottom: 10px; /* 答案框下方添加一些间距 */
  padding: 10px; /* 添加内边距 */
  //border: 1px solid #ccc; /* 添加边框 */
  box-sizing: border-box; /* 让边框计算在内部尺寸之内 */
  background: #FFFFFF;
  border-radius: 20px;
  word-wrap: break-word;
}


.left {
  margin-left: 30px;
  margin-top: 10px;
  width: 350px;
  height: 800px;
  background: rgba(255, 255, 255, 0.2); /* 将背景颜色改为白色并设置透明度为 40% */
  /* 移除阴影 */

}
.right {
  margin-right: 20px;
  margin-top: 10px;
  width: 1400px;
  height: 800px;
  box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
  border-radius: 40px;
}

.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}
.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    //min-height: 85px; /* 使用 min-height 以适应内容的高度 */
    width: 1003px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    //min-height: 321px; /* 使用 min-height 以适应内容的高度 */
    width: 1056px;
    background: #FFFFFF;
    border-radius: 20px;
  }
}
.select {
  margin-top: 187px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .selectdiv1 {
    margin-top: 60px;
    margin-left: 65px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  img {
    margin-top: 59px;
    margin-left: 155px;
    width: 40px;
    height: 31px;
  }

  .selectdiv2 {
    margin-top: 28px;
    margin-left: 31px;
    width: 192px;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    border: 1px solid #96AFFF;
    text-align: center;
    a{
      font-family: Microsoft YaHei UI;
      font-weight: 550;
      font-size: 28px;
      color: #2070CA;
      line-height: 92px;
      text-decoration: none;
    }

  }
}

.hand {
  margin-top: 51px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .handdiv1 {
    margin-left: 68px;
    margin-top: 60px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  .handdiv2 {
    margin-top: 65px;
    margin-left: 237px;
    height: 21px;
    font-family: Microsoft YaHei UI;
    font-weight: 300;
    font-size: 22px;
    color: #2070CA;
  }
}

.question {
  margin-top: 20px; /* 调整为与 neirong 容器的高度对应的值 */
  margin-left: 193px;
  width: 1056px;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.question div {
  margin-top: 19px; /* 调整为与 neirong 容器的高度对应的值 */
  margin-left: 52px;
  width: 871px;
  height: 77px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 28px;
  color: #929292;
  text-align: left;
}

.question img {
  margin-top: 18px; /* 调整为与 neirong 容器的高度对应的值 */
  margin-right: 80px;
  width: 31px;
  height: 41px;
  opacity: 0.93;
  cursor: pointer;
}

input[type="text"]:focus {
  outline: none; /* 移除聚焦时的轮廓 */
  border-color: transparent; /* 设置聚焦时边框颜色为透明 */
}

.question input {
  border-radius: 20px;
}


.leftbottom {
  margin-top: 0px; /* 添加顶部间距，根据需要调整间距大小 */
}
.container {
  background-size: 100% 100%;
  background-position: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow-y: auto;
}


</style>
<script>
import axios from "axios";
let mymessage = {}
export default {
  name: "recommended_1",
  created() {
    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
    this.getlist()
    sessionStorage.setItem('model',JSON.stringify(0))
  },
  data(){
    return{
      questionInput: "", // 用户输入的问题
      textContent:{},
      loading: false, // 如果需要加载状态，可以根据实际情况添加
      questionsWithAnswers: [], // 保存用户提问及接口返回的答案
      nameList:[],
      selectedKnowledgeBase: '',
      knowledgeBaseName: '',
      isShow: false,
      showAdditionalAnswers :false,
      additionalAnswers: [],
      sessionStorage: window.sessionStorage,

    }
  },
  methods:{

    getlist(){
      var that = this
      axios.get('/api/knowledge_base/list_knowledge_bases')
          .then((res) =>{
            console.log(res)
            that.nameList = res.data.data;
            console.log(that.nameList)
          })
          .catch((err) =>{
            console.log(err)
          })
    },
    clearllm(){
      this.questionsWithAnswers = [];
    },
    llm(){
      sessionStorage.clear()
      localStorage.clear()
      sessionStorage.setItem('llm',JSON.stringify(1))
      this.isShow = false
    },
    med(){
      sessionStorage.clear()
      // localStorage.clear()
      sessionStorage.setItem('med',JSON.stringify(2))
      this.isShow = true
    },
    guide() {
      sessionStorage.clear()
      // localStorage.clear()
      sessionStorage.setItem('guide',JSON.stringify(3))
      this.isShow = true
    },
    other(){
      sessionStorage.clear()
      sessionStorage.setItem('other',JSON.stringify(4))
    },
    junk(){
      this.$router.push('/selectionAssistant_2');
    },
    junk1(){
      this.$router.push('/upload');
    },
    // handleSend() {
    //   if(sessionStorage.getItem("model")){
    //     var that = this;
    //     // 将问题显示在界面上
    //     that.questionsWithAnswers.push({ question: that.questionInput, answer: '' });
    //     // 清空additionalAnswers数组
    //     that.additionalAnswers = [];
    //     that.sendMsg();
    //   }
    //   if(sessionStorage.getItem("llm")){
    //     var that = this;
    //     // 将问题显示在界面上
    //     that.questionsWithAnswers.push({ question: that.questionInput, answer: '' });
    //     that.sendMsg();
    //   }else if(sessionStorage.getItem("med")){
    //     this.knowledgeBaseName = '药品说明书'
    //     this.questionsWithAnswers.push({ question: this.questionInput, answer: '' });
    //     // 清空additionalAnswers数组
    //     that.additionalAnswers = [];
    //     this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
    //   }else if(sessionStorage.getItem("guide")){
    //
    //     this.knowledgeBaseName = '指南'
    //     this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
    //   }else if(sessionStorage.getItem("other")){
    //     this.knowledgeBaseName = '其他'
    //     this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
    //   }
    //
    // },
    handleSend() {
      this.additionalAnswers = [];

      var that = this;
      // 将问题显示在界面上
      this.questionsWithAnswers.push({ question: this.questionInput, answer: '', additionalAnswers: [], showAdditionalAnswers: false });

      that.Show = false;
      that.sendMsgWithKnowledgeBase();

      // if(sessionStorage.getItem("llm")){
      //   var that = this;
      //   // 将问题显示在界面上
      //   this.questionsWithAnswers.push({ question: this.questionInput, answer: '', additionalAnswers: [], showAdditionalAnswers: false });
      //
      //   that.sendMsg();
      // }else if(sessionStorage.getItem("med")){
      //   this.knowledgeBaseName = '药品说明书'
      //   this.questionsWithAnswers.push({ question: this.questionInput, answer: '', additionalAnswers: [], showAdditionalAnswers: true });
      //
      //   this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
      // }else if(sessionStorage.getItem("guide")){
      //   this.knowledgeBaseName = '指南'
      //   this.questionsWithAnswers.push({ question: this.questionInput, answer: '', additionalAnswers: [], showAdditionalAnswers: true });
      //
      //
      //   this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
      // }else if(sessionStorage.getItem("other")){
      //   this.knowledgeBaseName = '其他'
      //   this.questionsWithAnswers.push({ question: this.questionInput, answer: '', additionalAnswers: [], showAdditionalAnswers: true });
      //   this.sendMsgWithKnowledgeBase(this.knowledgeBaseName)
      // }
    },

    sendMsg(){
      var that = this
      const url = '/api/chat/chat';
      const data = {
        query: this.questionInput,
        conversation_id: "",
        history_len: 0,
        history: [],
        stream: false,
        model_name: "chatglm3-6b",
        temperature: 0.7,
        max_tokens: 0,
        prompt_name: "default"
      };
      axios.post(url, data,)
          .then((res) => {
            console.log(res);
            const responseData = JSON.parse(res.data.replace('data: ', ''));
            console.log(responseData);
            console.log(responseData.text);
            // const answerText = responseData.text.replace(/\n\n/g, '<br>').replace(/\n/g, '<br>');
            // 提取答案文本
            let answerText = responseData.text;
            // 定义关键词数组
            const keywords = ["ChatGLM3-6B", "清华大学", "智谱 AI"];
            // 检查答案中是否包含指定关键词
            let containsKeyword = false;
            for (const keyword of keywords) {
              if (answerText.includes(keyword)) {
                containsKeyword = true;
                break;
              }
            }
            // 如果答案中包含关键词，将答案替换为指定文本
            if (containsKeyword) {
              answerText = "我是存健康医疗大模型，由存健康开发训练，我的任务是针对用户的医疗问题和要求提供适当的答复和帮助。";
            }

            // 找到最后一个问题，并更新其答案
            that.questionsWithAnswers[that.questionsWithAnswers.length - 1].answer = answerText;
            that.questionInput = "";
            mymessage.success('发送成功');
          })
          // .then((res) =>{
          //   console.log(res)
          //   const responseData = JSON.parse(res.data.replace('data: ', '')); // 解析JSON对象
          //   console.log(responseData);
          //   console.log(responseData.text); // 输出"text"字段的内容
          //   const answerText = responseData.text.replace(/\n\n/g, '<br>').replace(/\n/g, '<br>'); // 将"text"字段的内容保存到answerText变量中
          //   // 将问题和答案添加到数组中
          //   that.questionsWithAnswers.push({ question: that.questionInput, answer: answerText });
          //   // 清空输入框
          //   this.questionInput = "";
          //   mymessage.success('发送成功')
          // })
          .catch((err) =>{
            console.log(err);
            mymessage.error('发送失败');
          });
    },
    sendMsgWithKnowledgeBase() {
      var that = this;
      const url = '/api/chat/knowledge_base_chat';
      let data = {
        query: this.questionInput,
        knowledge_base_name: "安全知识库（教材、论文）", // 将选择的知识库名称放在knowledge_base_name字段中
        history: [],
        top_k: localStorage.getItem("knowledge_match_num"),
        score_threshold: 0.6,
        stream: false,
        model_name: "chatglm3-6b",
        temperature: 0.7,
        max_tokens: 0,
        prompt_name: "default"
      };
      axios.post(url, data,)
          .then((res) => {
            console.log(res);
            const responseData = JSON.parse(res.data.replace('data: ', '')); // 解析JSON对象
            console.log(responseData);
            console.log(responseData.answer); // 输出"text"字段的内容
            console.log(responseData.docs);
            data.top_k=localStorage.getItem("knowledge_match_num");
            console.log(data.top_k);
            // 更新知识库数组内容
            // 清空additionalAnswers数组
            that.additionalAnswers = [];
            // 处理responseData.docs中的每个元素，去掉括号和链接中的乱码
            this.questionsWithAnswers[this.questionsWithAnswers.length - 1].additionalAnswers = responseData.docs.map((doc) => {
              return doc.replace(/\([^)]+\)/g, ''); // 去掉括号和链接中的乱码
            });

            let answerText1 = responseData.answer;
            // 定义关键词数组
            const keywords = ["ChatGLM3-6B", "清华大学", "智谱 AI","人工智能助手"];
            // 检查答案中是否包含指定关键词
            let containsKeyword = false;
            for (const keyword of keywords) {
              if (answerText1.includes(keyword)) {
                containsKeyword = true;
                break;
              }
            }
            // 如果答案中包含关键词，将答案替换为指定文本
            if (containsKeyword) {
              answerText1 = "我是存健康医疗大模型，由存健康开发训练，我的任务是针对用户的医疗问题和要求提供适当的答复和帮助。";
            }



            const answerText = answerText1.replace(/\n\n/g, '<br>').replace(/\n/g, '<br>'); // 将"text"字段的内容保存到answerText变量中


            // 将问题和答案添加到数组中
            // that.questionsWithAnswers.push({ question: that.questionInput, answer: answerText });
            that.questionsWithAnswers[that.questionsWithAnswers.length - 1].answer = answerText;
            // 清空输入框
            this.questionInput = "";
            mymessage.success('发送成功');
          })
          .catch((err) => {
            console.log(err);
            mymessage.error('发送失败');
          });
    },

    // filteredAnswer(answer) {
    //   // 检查答案中是否包含指定的关键词
    //   const keywords = ["ChatGLM3-6B", "清华大学", "智谱AI"];
    //   for (const keyword of keywords) {
    //     if (answer.includes(keyword)) {
    //       // 如果包含关键词，替换答案为指定文本
    //       return "我是存健康医疗大模型，由存健康开发训练，我的任务是针对用户的医疗问题和要求提供适当的答复和帮助。";
    //     }
    //   }
    //   // 如果不包含关键词，返回原答案
    //   return answer;
    // }

  }
};
</script>