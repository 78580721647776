<template>
  <div>
    <div class="single-line-component">
      <img src="./assets/右.png"  class="image">
      <div class="text">专用大模型应用场景--基于大模型的智慧营销</div>
      <img src="./assets/右.png"  class="image">
    </div>
    <div class="rightquestion">
      <ul class="product-list">
        <li class="product-item bkg1">
          <div class="product-image-container">
            <img src="./assets/转化率.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">转化率</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">广撒网方式用户体验差、转化率低</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg2">
          <div class="product-image-container">
            <img src="./assets/成本.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">成本</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">广撒网方式成本高、效果差</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg3">
          <div class="product-image-container">
            <img src="./assets/周转率.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">周转率</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">提前预判商品是否可能缺货</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg4">
          <div class="product-image-container">
            <img src="./assets/专家型销售.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">专家型销售</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">运用营销知识库赋能销售人员</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="background-container">
      <div class="content">
        <div class="single-line-component" style="margin-bottom: 50px;">
          <img src="./assets/右.png"  class="image">
          <div class="text">基于大模型的智慧营销 </div>
          <img src="./assets/右.png"  class="image">
        </div>
        <div class="image-container">
          <img src="./assets/图层.png" >
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.single-line-component {
  display: flex;
  align-items: center;
  justify-content: center; /* 在水平方向上居中对齐 */
  margin-top: 80px; /* 上边距为20px */
  margin-bottom: 100px; /* 下边距为20px */
}
.image {
  height: 28px; /* 设置图片高度与文字高度相同 */
  margin: 0 20px; /* 调整图片之间和图片与文字之间的间距 */
}
.text {
  font-size: 28px; /* 设置文字大小为32px */
  font-weight: bold;
  letter-spacing: 2px;
}
.rightquestion {
  text-align: center; /* 居中对齐 */
  margin-top: 50px;
  margin-bottom: 40px;
}
.product-list {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 20px; /* 顶部间距 */
  margin-bottom: 80px;
}
.product-item {
  width: 240px; /* 每个产品介绍框的宽度 */
  height: 280px; /* 每个产品介绍框的高度 */
  margin-right: 20px; /* 水平间距 */
  display: flex;
  flex-direction: column; /* 确保图片和文字垂直排列 */
  align-items: center; /* 水平居中对齐 */
  border-radius: 15px; /* 圆角边框 */
  background-color: #fff; /* 白色背景 */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  padding: 20px; /* 内边距 */
}
.product-image-container {
  width: 160px; /* 图片容器的宽度 */
  height: 160px; /* 图片容器的高度 */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 图片顶部与容器顶部对齐 */
}
.product-text-container {
  width: 240px; /* 文字框的宽度 */
  height: 100px; /* 文字框的高度 */
  padding-left: 15px; /* 左边距为15px */
  margin-top: 20px;
}
.product-title1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px; /* 文字框之间的垂直间距 */
}
.product-icon1 {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
  margin-top: 10px;
}
.product-text1 {
  font-size: 16px; /* 文字大小 */
  color: #666;
  text-align: left; /* 文字左对齐 */
  margin-top: 10px;
  letter-spacing: 2px; /* 设置字母间距为1px */
}
.background-container {
  width: 100%; /* 容器宽度为屏幕宽度 */
  height: 700px; /* 容器高度为600px */
  background-color: #EBF1FF; /* 背景颜色 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.content {
  max-width: 1000px; /* 最大宽度为1000px */
  width: 100%; /* 宽度自适应 */
  text-align: center; /* 文字水平居中 */
}
.image-container {
  width: 1000px; /* 图片容器宽度为1000px */
  margin: 0 auto; /* 水平居中 */
}

.image-container img {
  max-width: 100%; /* 图片宽度最大为容器宽度 */
  height: auto; /* 高度自适应 */
}

</style>
<script>
export default {
  name: "lookUptable",
};
</script>