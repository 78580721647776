import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 全局引入 css
import '@/assets/css/index.css'
import 'element-ui/lib/theme-chalk/index.css'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "small" });

new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
}).$mount("#app");
