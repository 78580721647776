<template>
  <div>
    <div class="header-container">
      <div class="header-content">
        基于大模型代码审计和自动修复系统
      </div>
    </div>
    <div class="single-line-component">
      <img src="./assets/右.png"  class="image">
      <div class="text">开源漏洞多 | 存在时间长 | 修复代价高</div>
      <img src="./assets/右.png"  class="image">
    </div>

    <div class="center-container">
      <div class="container1">
      <!-- 左边部分 -->
        <div class="left-side">
          <div class="top-box">
            <div class="top-content">
             软件开发过程中，编码阶段纠正问题所需成本最低
            </div>
         </div>
          <div class="bottom-box">
            <div class="description-column">
              <img src="./assets/蓝色图标.png" class="description-icon">
              <span>不了解使用的开源组件，人工检测速度慢</span>
            </div>
            <div class="description-column">
              <img src="./assets/蓝色图标.png" class="description-icon">
              <span>25%的高危和严重缺陷长期未被修复</span>
            </div>
            <div class="description-column">
              <img src="./assets/蓝色图标.png" class="description-icon">
              <span>当开源代码被纰漏时，很容易成为攻击对象并被利用</span>
            </div>
         </div>
       </div>
      <!-- 右边部分 -->
       <div class="right-side">
         <div class="top-box">
           <div class="top-content">
             代码安全是安全建设“基石”
           </div>
         </div>
         <div class="bottom-box">
           <div class="description-column">
             <img src="./assets/橙色图标.png" class="description-icon">
             <span>忽略了软件本身的安全，无法从产品源头解决问题</span>
           </div>
           <div class="description-column">
             <img src="./assets/橙色图标.png" class="description-icon">
             <span>未消减软件自身的安全漏洞，并未治本</span>
           </div>
           <div class="description-column">
             <img src="./assets/橙色图标.png" class="description-icon">
             <span>修复成本会随着时间而成倍增加</span>
           </div>
         </div>
       </div>
     </div>
    </div>
    <div class="single-line-component">
      <img src="./assets/右.png"  class="image">
      <div class="text">现有软件代码审计检测工具的缺陷</div>
      <img src="./assets/右.png"  class="image">
    </div>
    <div class="rightquestion">
      <ul class="product-list">
        <li class="product-item bkg1">
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/云.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text"> 规则引擎误报率高导致大量人工开销</span> <!-- 文字标题 -->
            </div>
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">现有的规则引擎工具误报率在30%以上，甚至达到了50%，大量的误报严重降低了工作效率</span>
            </div> <!-- 文字描述 -->
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">通过增加或者修改规则引擎的检测规则，不能显著降低误报率，泛化性差</span>
            </div> <!-- 文字描述 -->
          </div>
        </li>
        <li class="product-item bkg2">
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/云.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text"> 通用大模型无法实现快速的漏洞检测</span> <!-- 文字标题 -->
            </div>
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">现有的通用大模型在预训练阶段没有针对代码安全做训练</span>
            </div> <!-- 文字描述 -->
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">通用大模型效率低下</span>
            </div> <!-- 文字描述 -->
          </div>
        </li>
        <li class="product-item bkg3">
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/云.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text"> 代码漏洞缺乏解释和自动修复能力</span> <!-- 文字标题 -->
            </div>
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">代码修复主要依赖人工，漏洞知识库仅仅给出案例参考，效率低下</span>
            </div> <!-- 文字描述 -->
            <div class="product-description">
              <img src="./assets/蓝色图标.png" class="img"> <!-- 左边图标 -->
              <span style="font-size: 14px;">漏洞缺乏可解释性，有时候难以理解</span>
            </div> <!-- 文字描述 -->
          </div>
        </li>
      </ul>
    </div>
    <div class="container2">
      <div style="font-size: 28px;  margin-top: 100px;">
        AlSecurity解决的核心技术问题
      </div>
      <div style="font-size: 18px;  margin-top: 20px;color: #666; ">
        国产化、高性能、多场景
      </div>
      <div style="font-size: 18px;  margin-top: 10px;color: #666;margin-bottom: 40px  ">
        新一代基于行业大模型的软件代码安全检测、生成平台
      </div>
      <div class="rightquestion">
        <ul class="product-list">
          <li class="product-item bkg1">
            <div class="product-image-container">
              <img src="./assets/准确性.png" class="product-image">
            </div>
            <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">高准确率</div>
            <div class="product-text-container">
              <div class="product-title1">
                <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
                <span class="product-text1">大幅度提升准确率>90%</span> <!-- 文字标题 -->
              </div>
            </div>
          </li>
          <li class="product-item bkg2">
            <div class="product-image-container1">
              <img src="./assets/高性能.png" class="product-image">
            </div>
            <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">高性能</div>
            <div class="product-text-container">
              <div class="product-title">
                <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
                <span class="product-text1">相对于通用大模型</span> <!-- 文字标题 -->
              </div>
              <div class="product-title">
                <img src="./assets/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
                <span class="product-text1">扫描速度提升2-10倍实时检测</span> <!-- 文字标题 -->
              </div>
            </div>
          </li>
          <li class="product-item bkg3">
            <div class="product-image-container">
              <img src="./assets/集成多场景.png" >
            </div>
            <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">集成多场景</div>
            <div class="product-text-container">
              <div class="product-description1">
                <!-- 第一行 -->
                <div class="description-row">
                  <div class="description-column1">
                    <img src="./assets/蓝色图标.png" class="description-icon1">
                    <span>漏洞检测</span>
                  </div>
                  <div class="description-column1">
                    <img src="./assets/蓝色图标.png" class="description-icon1">
                    <span>代码修复</span>
                  </div>
                </div>
                <!-- 第二行 -->
                <div class="description-row">
                  <div class="description-column1">
                    <img src="./assets/蓝色图标.png" class="description-icon1">
                    <span>安全编程</span>
                  </div>
                  <div class="description-column1">
                    <img src="./assets/蓝色图标.png" class="description-icon1">
                    <span>单元测试</span>
                  </div>
                </div>
                <div class="description-row">
                  <div class="description-column1">
                    <img src="./assets/蓝色图标.png" class="description-icon1">
                    <span>代码审查</span>
                  </div>
                </div>
              </div> <!-- 文字描述 -->
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="single-line-component">
      <img src="./assets/右.png"  class="image">
      <div class="text">大模型赋能开发、测试</div>
      <img src="./assets/右.png"  class="image">
    </div>

    <div class="image-component">
      <div class="image-container">
        <div class="image-wrapper" style="width: 500px;">
          <img src="./assets/ide客户端.png"  class="image">
          <div class="image-title">IDE客户端</div>
        </div>
        <div class="image-wrapper" style="width: 200px;">
          <img src="./assets/切换.png"  class="image" style="height: 70px;">
        </div>
        <div class="image-wrapper" style="width: 500px;">
          <img src="./assets/后台管理端.png"  class="image">
          <div class="image-title">后台管理端</div>
        </div>
      </div>
    </div>
    <div class="image-component" style="margin-top: 100px">
      <div class="image-container">
        <div class="image-wrapper" style="width: 610px;">
          <img src="./assets/安全检测.png"  class="image">
          <div class="image-title">安全检测</div>
        </div>
        <div class="image-wrapper" style="width: 610px;">
          <img src="./assets/开发助手.jpg"  class="image">
          <div class="image-title">开发助手</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.header-container {
  width: 100%; /* 宽度为屏幕宽度 */
  height: 100px; /* 高度为80px */
  background-color: #EBF1FF; /* 淡蓝色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.container2 {
  width: 100vw; /* 宽度为屏幕宽度 */
  height: 800px; /* 高度为800px */
  background-image: url('./assets/bg.png'); /* 背景图片路径 */
  background-size: cover; /* 图片大小自适应容器 */
  background-position: center; /* 图片居中 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 在垂直方向上顶部对齐 */
  align-items: center;

}
.header-content {
  font-size: 28px; /* 字体大小 */
  color: #333; /* 字体颜色 */
  font-weight: bold; /* 字体加粗 */
}

.single-line-component {
  display: flex;
  align-items: center;
  justify-content: center; /* 在水平方向上居中对齐 */
  margin-top: 100px; /* 上边距为20px */
  margin-bottom: 60px; /* 下边距为20px */
}
.image {
  height: 28px; /* 设置图片高度与文字高度相同 */
  margin: 0 20px; /* 调整图片之间和图片与文字之间的间距 */
}
.text {
  font-size: 28px; /* 设置文字大小为32px */
  font-weight: bold;
  letter-spacing: 2px;
}

.center-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  margin-top: 30px; /* 上边距为20px */
  margin-bottom: 80px; /* 下边距为20px */
}
.container1 {
  width: 1000px;
  display: flex;
  justify-content: center; /* 水平居中 */
}

.left-side, .right-side {
  width: 50%; /* 左右两边各占50%宽度 */
}

.left-side {
  background-color: #5E80FE; /* 左边顶部背景色为#5E80FE */
}

.right-side {
  background-color: #FEA549; /* 右边顶部背景色为#FEA549 */
}

.top-box {
  width: 500px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}

.top-content {
  text-align: center;
  font-weight: bold; /* 字体加粗 */
}

.bottom-box {
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 垂直方向居中对齐 */
  align-items: flex-start; /* 水平方向内容左对齐 */

}


.left-side .bottom-box {
  background-color: #EBF1FF; /* 左边底部背景色为#EBF1FF */
}

.right-side .bottom-box {
  background-color: #FFF9EB; /* 右边底部背景色为#FFF9EB */
}

.product-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px; /* 文字框之间的垂直间距 */
}
.product-title1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px; /* 文字框之间的垂直间距 */
}
.description-column {
  margin-bottom: 20px; /* 垂直方向上的固定间距为20px */
}

.description-column1 {
  display: flex;
  align-items: center;
}
.description-row {
  display: flex;
  justify-content: space-between; /* 左右对齐，并且元素之间的距离相等 */
  margin-top: 10px; /* 行之间的垂直间距 */
  margin-right: 30px; /* 图标和文字之间的水平间距 */
  margin-left: 20px; /* 图标和文字之间的水平间距 */
}


.description-icon {
  height: 1em; /* 设置图标的高度为文字的行高，1em 表示当前文字的行高 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
  margin-left: 40px; /* 图标和文字之间的水平间距 */
}

.description-icon1 {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 5px; /* 图标和文字之间的水平间距 */
}
.rightquestion {
  text-align: center; /* 居中对齐 */
  margin-top: 50px;
}

.product-list {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 20px; /* 顶部间距 */
  margin-bottom: 80px;
}

.product-item {
  width: 240px; /* 每个产品介绍框的宽度 */
  height: 320px; /* 每个产品介绍框的高度 */
  margin-right: 20px; /* 水平间距 */
  display: flex;
  flex-direction: column; /* 确保图片和文字垂直排列 */
  align-items: center; /* 水平居中对齐 */
  border-radius: 15px; /* 圆角边框 */
  background-color: #fff; /* 白色背景 */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  padding: 20px; /* 内边距 */
}

.product-text-container {
  width: 240px; /* 文字框的宽度 */
  height: 100px; /* 文字框的高度 */
  padding-left: 15px; /* 左边距为15px */
  margin-top: 20px;
}

.product-title {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}

.product-text {
  font-size: 18px; /* 文字大小 */
  font-weight: bold; /* 文字加粗 */
  text-align: left; /* 文字左对齐 */
}
.product-text1 {
  font-size: 14px; /* 文字大小 */
  color: #666;
  font-weight: bold; /* 文字加粗 */
  text-align: left; /* 文字左对齐 */
  margin-top: 10px;
}

.product-icon {
  width: 30px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
}

.product-icon1 {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
  margin-top: 10px;
}

.product-image-container {
  width: 160px; /* 图片容器的宽度 */
  height: 160px; /* 图片容器的高度 */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 图片顶部与容器顶部对齐 */
}

.product-description {
  display: flex; /* 使用 Flexbox 布局 */
  align-items: flex-start; /* 垂直方向顶部对齐 */
  font-size: 12px; /* 描述文字大小 */
  line-height: 1.5;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 28px;
  color: #666; /* 设置文字颜色为灰色 */
}
.product-description1 {
  font-size: 12px; /* 描述文字大小 */
  line-height: 1.5;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 20px;
}
.product-description img {
  width: 15px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
  margin-left: 10px;
}
.image-component {
  text-align: center;
  margin-top: 70px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center; /* 让图片在垂直方向上居中对齐 */

}

.image-wrapper {
  margin: 0 15px; /* 调整图片之间的间距 */
  text-align: center;
}

.image {
  max-width: 100%;
  height: auto;
}

.image-title {
  margin-top: 10px; /* 调整标题与图片的垂直间距 */
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

</style>
<script>
import axios from "axios";
let mymessage = {}
export default {
  name: "paperTemplate",
  created() {
    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
  },
  data(){
    return{

    }
  },
  methods:{

  }
};
</script>