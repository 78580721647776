<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo8.png">
        <div class="title">长江文化大模型</div>
      </div>

      <div class="returnindex">
        <img src="@/assets/return8.png">
        <a href="/">
          <div>长江文化新高地建设平台</div>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <button class="leftbutton1" style="background-color: #58a8f0;">新建对话</button>
        <div class="leftsearch">
          <img src="@/assets/search.png">
          <div class="searchinput">
            <input type="text" placeholder="搜索历史记录">
          </div>
        </div>
        <div class="dialog">请根据《长江文化在文化创……</div>
        <div class="leftbottom">
          <ul>
            <li><a href="#">选题助手</a></li>
            <li class="selected"><a href="#">推荐期刊</a></li>
            <li><a href="#">论文模板</a></li>
            <li><a href="#">长江百科</a></li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="righttitle">长江文化大模型</div>
        <div class="righhtquestion">
          <img src="./assets/question.png">
          <div>
            <div>请根据《长江文化在文化创意产业中的价值挖掘与应用研究》这个选题，推荐3个合适的投稿期刊。</div>
          </div>
        </div>
        <div class="rightanswer">
          <img src="./assets/answer.png">
          <div class="rightanswercontent">
            <div class="div1">1.《产业经济研究》</div>
            <div class="div2"></div>
            <div class="div3"><a href="#">《产业经济研究》</a>（Industrial Economics Research）创刊于2002年，由江苏省教育厅主管、
              南京财经大学主办，是我国第一本专门研究产业组织、产业结构、产业政策与产业规制问题的
              专业学术刊物。</div>
            <div class="div4">2.《产经评论》</div>
            <div class="div5"></div>
          </div>
        </div>
        <div class="question">
          <div>请根据《长江文化在文化创意产业中的价值挖掘与应用研究》这个选题，推荐3个合适的投稿期刊。</div>
          <img src="./assets/arrow.png">
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>
.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}

.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    width: 1003px;
    height: 85px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    width: 1056px;
    height: 583px;
    background: #FFFFFF;
    border-radius: 20px;

    .div1 {
      margin-top: 37px;
      margin-left: 53px;
      height: 24px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      color: #5C6062;
    }

    .div2 {
      margin-top: 20px;
      margin-left: 70px;
      background: url('./assets/book2.png') no-repeat -50px -40px;
      width: 147px;
      height: 200px;
    }

    .div3 {
      margin-left: 93px;
      margin-right: 48px;
      width: 915px;
      height: 101px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      color: #5C6062;
      line-height: 34px;
      text-align: left;

      a {
        color: #58A8F0;
      }
    }

    .div4 {
      margin-top: 49px;
      margin-left: 53px;
      height: 24px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      color: #5C6062;
    }

    .div5 {
      margin-top: 20px;
      margin-left: 70px;
      background: url('./assets/book3.png') no-repeat -50px -330px;
      width: 140px;
      height: 117px;
    }
  }
}

.question {
  margin-top: 52px;
  margin-left: 193px;
  width: 1056px;
  height: 165px;
  background: #FFFFFF;
  box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  div {
    margin-top: 33px;
    margin-left: 52px;
    width: 871px;
    height: 77px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #929292;
    text-align: left;
  }

  img {
    margin-top: 27px;
    margin-right: 80px;
    width: 31px;
    height: 41px;
    opacity: 0.93;
    cursor: pointer;
  }
}
</style>
<script>
export default {
  name: "recommended_3",
};
</script>