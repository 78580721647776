import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";
import index from "../views/pages/home/index.vue"
import modelhome from "../views/pages/modelhome/modelhome.vue"
import selectionAssistant_1 from "../views/pages/selectionAssistant/selectionAssistant_1.vue"
import selectionAssistant_2 from "../views/pages/selectionAssistant/selectionAssistant_2.vue"
import recommended_1 from "../views/pages/recommended/recommended_1.vue"
import recommended_2 from "../views/pages/recommended/recommended_2.vue"
import recommended_3 from "../views/pages/recommended/recommended_3.vue"
import paperTemplate from "../views/pages/paperTemplate/paperTemplate.vue"
import lookUptable from "../views/pages/lookUptable/lookUptable.vue"
import upload from "../views/pages/upload/upload.vue"
import newKnow from "../views/pages/newKnow/newKnow.vue"
import deskHandbook from "../views/pages/deskHandbook/deskHandbook.vue"
import encyclopedia from "../views/pages/encyclopedia/encyclopedia.vue"
import register from "../views/pages/register/register.vue"
import login from "../views/pages/user/login.vue"
import signup from "../views/pages/user/signup.vue"
import userHome from "../views/pages/user/userHome.vue"
import userMenu from "../views/pages/user/userMenu.vue"
import test from "../views/pages/user/test.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/signup",
    name: "signup",
    component: signup,
  },
  {
    path: "/modelhome",
    name: "modelhome",
    component: modelhome,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/lookUptable",
    name: "lookUptable",
    component: lookUptable,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: upload,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/newKnow",
    name: "newKnow",
    component: newKnow,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/deskHandbook",
    name: "deskHandbook",
    component: deskHandbook,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/selectionAssistant_1",
    name: "selectionAssistant_1",
    component: selectionAssistant_1,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/selectionAssistant_2",
    name: "selectionAssistant_2",
    component: selectionAssistant_2,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/recommended_1",
    name: "recommended_1",
    component: recommended_1,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/recommended_2",
    name: "recommended_2",
    component: recommended_2,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/recommended_3",
    name: "recommended_3",
    component: recommended_3,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/paperTemplate",
    name: "paperTemplate",
    component: paperTemplate,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/encyclopedia",
    name: "encyclopedia",
    component: encyclopedia,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/register",
    name: "register",
    component: register,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/userHome",
    name: "userHome",
    component: userHome,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/userMenu",
    name: "userMenu",
    component: userMenu,
    meta: {        //是路由的原数据
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/test",
    name: "test",
    component: test,
    meta: {        //是路由的原数据
      requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];



const router = new VueRouter({
  mode: 'hash',
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
//     if (localStorage.getItem('token')) { // 通过vuex state获取当前的token是否存在    注意token有时效性
//       next();//存在就说明登录了，放行
//     }
//     else {
//       router.push('./login');
//     }
//   }
//   else {
//     next();
//   }
// })

export default router;
