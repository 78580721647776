<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo8.png">
        <div class="title">医疗用药大模型</div>
      </div>

      <div class="returnindex">
        <img src="@/assets/return8.png">
        <a href="/">
          <div>医疗用药问答平台</div>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <button class="leftbutton1" style="background-color: #58a8f0;">清空对话</button>
        <div class="leftbottom">
          <ul>
            <li><a href="/selectionAssistant_1">知识库列表</a></li>
            <li><a href="/recommended_1">与LLM对话</a></li>
            <li><a href="/paperTemplate">药品说明书知识库</a></li>
            <li><a href="/encyclopedia">指南知识库</a></li>
            <li><a href="/lookUptable">速查手册知识库</a></li>
            <li class="selected"><a href="/deskHandbook">案头手册知识库</a></li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="righttitle">医疗用药大模型</div>
        <div class="righhtquestion">
          <img src="./assets/question.png">
          <div>
            <div>我想要《产业经济评论》这个期刊的投稿信息。</div>
          </div>
        </div>
        <div class="rightanswer">
          <img src="./assets/answer.png">
          <div class="rightanswercontent">
            <div class="div1">好的，为您提供《产业经济评论》这个期刊的投稿须知，请点击下载查看。</div>
          </div>
        </div>
        <div class="question">
          <input type="text" placeholder="请输入您的问题" v-model="questionInput" style="width: 100%;border: none;font-size: 20px">
          <img src="./assets/arrow.png" @click="sendMsg()">
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>
.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}

.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    width: 1003px;
    height: 85px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    width: 1056px;
    height: 321px;
    background: #FFFFFF;
    border-radius: 20px;

    .div1 {
      margin-top: 32px;
      margin-left: 52px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      text-align: left;
      color: #4C4C4C;
    }

    .div2 {
      margin-top: 52px;
      margin-left: 53px;
      text-align: left;
    }

    .div2 a {
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      color: #3B38CE;
      text-decoration-line: underline;
    }

    .div3 {
      margin-top: 43px;
      margin-left: 55px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 18px;
      color: #2070CA;
      text-align: left;
    }

    .div4 {
      margin-top: 30px;
      margin-left: 651px;

      img {
        width: 389px;
        height: 79px;
      }
    }
  }
}

.question {
  margin-top: 313px;
  margin-left: 193px;
  width: 1056px;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  div {
    margin-top: 33px;
    margin-left: 52px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #929292;
    text-align: left;
  }

  img {
    margin-top: 27px;
    margin-right: 80px;
    width: 31px;
    height: 41px;
    opacity: 0.93;
    cursor: pointer;
  }
}
</style>
<script>
export default {
  name: "lookUptable",
};
</script>