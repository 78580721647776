import { render, staticRenderFns } from "./recommended_1.vue?vue&type=template&id=0d11a27d&scoped=true"
import script from "./recommended_1.vue?vue&type=script&lang=js"
export * from "./recommended_1.vue?vue&type=script&lang=js"
import style0 from "@/views/commonstyle/modelstyle.css?vue&type=style&index=0&id=0d11a27d&prod&scoped=true&lang=css&external"
import style1 from "./recommended_1.vue?vue&type=style&index=1&id=0d11a27d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d11a27d",
  null
  
)

export default component.exports