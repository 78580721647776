import { render, staticRenderFns } from "./encyclopedia.vue?vue&type=template&id=0780e882&scoped=true"
import script from "./encyclopedia.vue?vue&type=script&lang=js"
export * from "./encyclopedia.vue?vue&type=script&lang=js"
import style0 from "@/views/commonstyle/modelstyle.css?vue&type=style&index=0&id=0780e882&prod&scoped=true&lang=css&external"
import style1 from "./encyclopedia.vue?vue&type=style&index=1&id=0780e882&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0780e882",
  null
  
)

export default component.exports