<template>
  <div>
     <div class="single-line-component">
       <img src="./assets/右.png"  class="image">
       <div class="text">专用大模型应用场景--智能服务助手</div>
       <img src="./assets/右.png"  class="image">
     </div>
     <div class="provide-image-container">
       <img src="./assets/产品2.png" >
     </div>
    <div class="single-line-component" style="margin-top: 100px;">
      <img src="./assets/右.png"  class="image">
      <div class="text">基于大模型的智能助手 </div>
      <img src="./assets/右.png"  class="image">
    </div>

    <div class="rightquestion">
      <ul class="product-list" >
        <li class="product-item bkg1" >
          <div class="product-image-container">
            <img src="./assets/政策助手.png" class="product-image">
          </div>
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/图片5.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text">政策助手</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg2">
          <div class="product-image-container">
            <img src="./assets/客服助手.png" class="product-image">
          </div>
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/图片5.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text">客服助手</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg3" >
          <div class="product-image-container">
            <img src="./assets/安全知识库.png" class="product-image">
          </div>
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/图片5.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text">企业知识库</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg4">
          <div class="product-image-container">
            <img src="./assets/培训助手.png" class="product-image">
          </div>
          <div class="product-text-container">
            <div class="product-title">
              <img src="./assets/图片5.png" class="product-icon"> <!-- 图标 -->
              <span class="product-text">培训助手</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="less" scoped>
.single-line-component {
  display: flex;
  align-items: center;
  justify-content: center; /* 在水平方向上居中对齐 */
  margin-top: 80px; /* 上边距为20px */
  margin-bottom: 40px; /* 下边距为20px */
}
.image {
  height: 32px; /* 设置图片高度与文字高度相同 */
  margin: 0 20px; /* 调整图片之间和图片与文字之间的间距 */
}
.text {
  font-size: 32px; /* 设置文字大小为32px */
  letter-spacing: 2px;
}
.provide-image-container {
  width: 1200px; /* 设置容器宽度为1200px */
  margin: 70px auto 30px; /* 30px 的顶部外边距，然后水平居中 */
  text-align: center; /* 图片也居中显示 */
}

.provide-image-container img {
  max-width: 100%; /* 图片最大宽度为容器宽度 */
  height: auto; /* 让浏览器自动计算图片的高度，保持比例 */
  display: block; /* 去除图片默认的行内元素空白间距 */
  margin: 0 auto; /* 图片水平居中 */
}
.rightquestion {
  text-align: center; /* 居中对齐 */
  margin-top: 50px;
  margin-bottom: 100px;
}

.product-list {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 20px; /* 顶部间距 */
}

.product-item {
  width: 220px; /* 每个产品介绍框的宽度 */
  height: 260px; /* 每个产品介绍框的高度 */
  margin-right: 20px; /* 水平间距 */
  display: flex;
  flex-direction: column; /* 确保图片和文字垂直排列 */
  //justify-content: space-between; /* 图片和文字之间的间距 */
  align-items: center; /* 水平居中对齐 */
  border-radius: 15px; /* 圆角边框 */
  background-color: rgba(128, 128, 128, 0.05); /* 透明度10%的灰色背景 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  padding: 20px; /* 内边距 */
}

.product-image-container {
  width: 200px; /* 图片容器的宽度 */
  height: 200px; /* 图片容器的高度 */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 图片顶部与容器顶部对齐 */
}

.product-image {
  max-width: 100%; /* 图片最大宽度为容器宽度 */
  max-height: 100%; /* 图片最大高度为容器高度 */
}

.product-text-container {
  width: 220px; /* 文字框的宽度 */
  text-align: center; /* 水平居中对齐 */
}

.product-title {
  display: flex;
  align-items: flex-start;
  margin-top: 20px; /* 文字框之间的垂直间距 */
  justify-content: center;
}

.product-icon {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
}

.product-text {
  font-size: 18px; /* 文字大小 */
  font-weight: bold; /* 字体加粗 */
}

</style>
<script>
import axios from "axios";
let mymessage = {}
export default {
  name: "newKnow",
  created() {
    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
  },
  data(){
    return{

    }
  },
  methods:{

  }
};
</script>