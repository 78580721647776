<template>
  <div>
    <div class="single-line-component">
      <img src="./asset/右.png"  class="image">
      <div class="text">基于大模型智能审核平台 </div>
      <img src="./asset/右.png"  class="image">
    </div>
    <div class="rightquestion">
      <ul class="product-list">
        <li class="product-item bkg1">
          <div class="product-image-container">
            <img src="./asset/场景ICON.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">审核场景痛点</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">人工审核，工作量大</span> <!-- 文字标题 -->
            </div>
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">关键字匹配误报高</span> <!-- 文字标题 -->
            </div>
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">无法实现语义检测</span> <!-- 文字标题 -->
            </div>
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">大量的误报和漏报</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
        <li class="product-item bkg2">
          <div class="product-image-container">
            <img src="./asset/审核.png" class="product-image">
          </div>
          <div  style="font-size: 20px; margin-top: 20px; font-weight: bold;">大模型审核</div>
          <div class="product-text-container">
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1"> 基于显示规则+语义审核</span> <!-- 文字标题 -->
            </div>
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1"> 高准确率、低误报</span> <!-- 文字标题 -->
            </div>
            <div class="product-title1">
              <img src="./asset/蓝色图标.png" class="product-icon1"> <!-- 图标 -->
              <span class="product-text1">监管单位审核、媒体审核等</span> <!-- 文字标题 -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="less" scoped>
.single-line-component {
  display: flex;
  align-items: center;
  justify-content: center; /* 在水平方向上居中对齐 */
  margin-top: 80px; /* 上边距为20px */
  margin-bottom: 100px; /* 下边距为20px */
}
.image {
  height: 28px; /* 设置图片高度与文字高度相同 */
  margin: 0 20px; /* 调整图片之间和图片与文字之间的间距 */
}
.text {
  font-size: 28px; /* 设置文字大小为32px */
  font-weight: bold;
  letter-spacing: 2px;
}

.rightquestion {
  text-align: center; /* 居中对齐 */
  margin-top: 50px;
  margin-bottom: 40px;
}
.product-list {
  display: flex;
  justify-content: center; /* 水平居中对齐 */
  margin-top: 20px; /* 顶部间距 */
  margin-bottom: 120px;
}
.product-item {
  width: 280px; /* 每个产品介绍框的宽度 */
  height: 360px; /* 每个产品介绍框的高度 */
  margin-right: 100px; /* 水平间距 */
  margin-left: 100px; /* 水平间距 */
  display: flex;
  flex-direction: column; /* 确保图片和文字垂直排列 */
  align-items: center; /* 水平居中对齐 */
  border-radius: 15px; /* 圆角边框 */
  background-color: #fff; /* 白色背景 */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  padding: 30px; /* 内边距 */
}
.product-image-container {
  width: 140px; /* 图片容器的宽度 */
  height: 160px; /* 图片容器的高度 */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 图片顶部与容器顶部对齐 */
}
.product-text-container {
  width: 240px; /* 文字框的宽度 */
  height: 100px; /* 文字框的高度 */
  padding-left: 50px; /* 左边距为15px */
  margin-top: 20px;
}
.product-title1 {
  display: flex;
  align-items: flex-start;

}
.product-icon1 {
  width: 20px; /* 图标宽度 */
  height: auto; /* 图标高度自适应 */
  margin-right: 10px; /* 图标和文字之间的水平间距 */
  margin-top: 10px;
}
.product-text1 {
  font-size: 16px; /* 文字大小 */
  color: #333;
  text-align: left; /* 文字左对齐 */
  margin-top: 10px;

}

</style>
<script>
export default {
  name: "modelhome",

};
</script>