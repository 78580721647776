<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo8.png">
        <div class="title">长江文化大模型</div>
      </div>

      <div class="returnindex">
        <img src="@/assets/return8.png">
        <a href="/">
          <div>长江文化新高地建设平台</div>
        </a>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <button class="leftbutton1" style="background-color: #58a8f0;">新建对话</button>
        <div class="leftsearch">
          <img src="@/assets/search.png">
          <div class="searchinput">
            <input type="text" placeholder="搜索历史记录">
          </div>
        </div>
        <div class="dialog">请根据《长江文化在文化创……</div>
        <div class="leftbottom">
          <ul>
            <li><a href="#">选题助手</a></li>
            <li class="selected"><a href="#">推荐期刊</a></li>
            <li><a href="#">论文模板</a></li>
            <li><a href="#">长江百科</a></li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="righttitle">长江文化大模型</div>
        <div class="righhtquestion">
          <img src="./assets/question.png">
          <div>
            <div>请根据《长江文化在文化创意产业中的价值挖掘与应用研究》这个选题，推荐3个合适的投稿期刊。</div>
          </div>
        </div>
        <div class="rightanswer">
          <img src="./assets/answer.png">
          <div class="rightanswercontent">
            <div class="div0"></div>
            <div class="div1"><a href="#" style="color: rgb(101,170,244);">《经济研究》</a>（国内统一刊号:CN 11-1081/F；国际标准刊号:ISSN
              0577-9154；国内邮发代
              号：2-251；国外发行代号:M16）创办于1955年，是综合性经济理论类期刊，由中国社会科
              学院经济研究所主办，中国社会科学院主管。</div>
            <div class="div2">
              <div class="div2div1">获取对应期刊的投稿 须知或论文模板？</div>
              <img src="./assets/bluearrow.png">
              <div class="div2div2">论文模版</div>
            </div>
            <div class="div3">更多咨询信息请联系_湖北省长江文化研究院官方邮箱：service003@yrcri.ac.cn</div>
            <div class="div4">
              <img src="./assets/dianzan.png">
            </div>
          </div>
        </div>
        <div class="question">
          <div>请根据《长江文化在文化创意产业中的价值挖掘与应用研究》这个选题，推荐3个合适的投稿期刊。</div>
          <img src="./assets/arrow.png">
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>
.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}

.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    width: 1003px;
    height: 85px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    width: 1056px;
    height: 583px;
    background: #FFFFFF;
    border-radius: 20px;

    .div0 {
      margin-left: 91px;
      margin-top: 9px;
      background: url('./assets/book1.png') no-repeat -44px 40px;
      width: 147px;
      height: 200px;
    }

    .div1 {
      margin-top: 9px;
      margin-left: 96px;
      margin-right: 51px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      text-align: left;
      color: #4C4C4C;
    }

    .div2 {
      margin-top: 36px;
      margin-left: 263px;
      width: 523px;
      height: 93px;
      background: #FFFFFF;
      box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
      border-radius: 20px;
      display: flex;

      .div2div1 {
        margin-left: 41px;
        margin-top: 23px;
        width: 170px;
        height: 49px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        font-size: 18px;
        color: #4C4C4C;
      }

      img {
        margin-left: 99px;
        margin-top: 37px;
        width: 25px;
        height: 20px;
      }

      .div2div2 {
        margin-top: 17px;
        margin-left: 35px;
        width: 122px;
        height: 58px;
        background: #FFFFFF;
        border-radius: 13px;
        border: 1px solid #96AFFF;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        font-size: 18px;
        color: #2070CA;
        line-height: 58px;
        text-align: center
      }
    }

    .div2 a {
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      color: #3B38CE;
      text-decoration-line: underline;
    }

    .div3 {
      margin-top: 27px;
      margin-left: 217px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 18px;
      color: #2070CA;
      text-align: left;
    }

    .div4 {
      margin-top: 7px;
      margin-left: 663px;

      img {
        width: 389px;
        height: 79px;
      }
    }
  }
}

.question {
  margin-top: 52px;
  margin-left: 193px;
  width: 1056px;
  height: 165px;
  background: #FFFFFF;
  box-shadow: 0px 1px 12px 0px rgba(32, 112, 202, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;

  div {
    margin-top: 33px;
    margin-left: 52px;
    width: 871px;
    height: 77px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #929292;
    text-align: left;
  }

  img {
    margin-top: 27px;
    margin-right: 80px;
    width: 31px;
    height: 41px;
    opacity: 0.93;
    cursor: pointer;
  }
}
</style>
<script>
export default {
  name: "recommended_3",
};
</script>