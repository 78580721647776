<template>
  <div class="test">
    <div style="background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 6px #dfdfdf;
            padding: 20px;
            margin: 20px 10px;">
      <a href="#" style="color: #333;
            text-decoration: none;
            font-size: 24px;">111</a>
      <p style="color: #8a7f7f;
            font-size: 16px;">2222
      </p>
    </div>
    <div style="background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 6px #dfdfdf;
            padding: 20px;
            margin: 20px 10px;">
      <a href="#" style="color: #333;
            text-decoration: none;
            font-size: 24px;">111</a>
      <p style="color: #8a7f7f;
            font-size: 16px;">2222
      </p>
    </div>
    <div style="background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 6px #dfdfdf;
            padding: 20px;
            margin: 20px 10px;">
      <a href="#" style="color: #333;
            text-decoration: none;
            font-size: 24px;">111</a>
      <p style="color: #8a7f7f;
            font-size: 16px;">2222
      </p>
    </div>
    <div style="background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 6px #dfdfdf;
            padding: 20px;
            margin: 20px 10px;">
      <a href="#" style="color: #333;
            text-decoration: none;
            font-size: 24px;">111</a>
      <p style="color: #8a7f7f;
            font-size: 16px;">2222
      </p>
    </div>
    <div style="background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 6px #dfdfdf;
            padding: 20px;
            margin: 20px 10px;">
      <a href="#" style="color: #333;
            text-decoration: none;
            font-size: 24px;">111</a>
      <p style="color: #8a7f7f;
            font-size: 16px;">2222
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>