<template>
  <div>
    <!-- 使用 v-if 来根据 localStorage 中是否存在token来显示不同的内容 -->
    <div v-if="username">
      <el-menu class="el-menu-demo" mode="horizontal" style="width: 200px;">
        <el-submenu index="1">
          <template slot="title">
            欢迎您，{{ username }}
          </template>
          <el-menu-item index="1-1" style="text-align: center;" @click="userHome">个人主页</el-menu-item>
          <el-menu-item index="1-2" style="text-align: center;" @click="resetPassword">重置密码</el-menu-item>
          <el-menu-item index="1-3" style="text-align: center;" @click="loginout">退出登录</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div v-else>
      <el-menu><el-menu-item index=" 2-1" style="width:200px;text-align: center;"
          @click="loginin">登录</el-menu-item></el-menu>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
    };
  },
  mounted() {
    const storedUsername = localStorage.getItem('user');
    if (storedUsername) {
      this.username = storedUsername;
    }
  },
  methods: {
    userHome() {
      this.$router.push('/userHome')
    },
    resetPassword() {

    },
    loginout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.reload()
    },
    loginin() {
      this.$router.push('/login')
    },
  },
};
</script>
<style scoped></style>