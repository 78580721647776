<template>
  <div class="container">
    <div class="header">
<!--      <div class="logo">-->
<!--&lt;!&ndash;        <img src="@/assets/clogo.png">&ndash;&gt;-->
<!--        <div class="title" style="color: #05586a;margin-left: 300px;font-size: 24px;white-space: nowrap;">AISecurity-基于大模型的网络空间安全智能问答系统</div>-->
<!--      </div>-->
      <div class="returnindex" style="margin-left: 1200px;">
        <img src="@/assets/return8.png">
          <div style="color: #05586a;font-size: 20px;" @click="junk()">AISecurity-基于大模型的网络空间安全智能问答系统</div>
      </div>
    </div>

    <div class="content">
      <div class="right" style="height: 1065px;">
        <div class="righttext">
          <div class="upload-header3">参数设置:</div>
          <div class="knowname">
            <div class="knowname-item">
              <span class="label">温度</span>
              <span class="description">（调整模型生成文本时创造性和多样性，范围为0~1）</span>
              <el-input-number v-model="temperature" @change="handleChange('temperature_size')" :min="0" :max="1" label="描述文字" :step="0.1" ></el-input-number>
            </div>
            <div class="knowname-item">
              <span class="label">知识库匹配条数</span>
              <span class="description">（检索匹配的知识库的数量，范围为0~20）</span>
              <el-input-number v-model="knowledgematchnum" @change="handleChange('knowledge_match_num')" :min="0" :max="20" label="描述文字"></el-input-number>
            </div>
            <div class="knowname-item">
              <span class="label">知识库匹配阈值</span>
              <span class="description">（检索时与知识库的匹配值，范围为0~1） </span>
              <el-input-number v-model="knowledgematchmax" @change="handleChange('knowledge_match_max')" :min="0" :max="1000" label="描述文字" :step="0.1"></el-input-number>
            </div>
          </div>
          <div class="upload-header4">
            <el-button type="primary" @click="add()">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/views/commonstyle/modelstyle.css" scoped></style>
<style lang="less" scoped>
.rightcontent{
  width: 1100px;
  border: 1px solid green;
  margin-left: 153px;
}
//.container {
//  background-image: url('@/assets/BG.png');
//  background-size: 100% 100%;
//  background-position: center;
//  flex-direction: column;
//  width: 100%;
//  height: 100vh;
//  display: flex;
//  overflow-y: auto;
//}
.allknowname{
  margin-top: 20px;
}
.btn{
  margin-top: 20px;
}
hr{
  margin: 20px 0px;
}
.upload-demo{
  height: 300px;
}
.righttext span{
  font-size: 18px;
}
select{
  width: 100%;
  height: 40px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.4;
}

.knowname {
  display: flex; /* 使用 Flexbox 布局 */
  flex-wrap: wrap; /* 允许元素换行 */
}

.knowname > div {
  flex: 1 1 50%; /* 设置子元素占据父元素宽度的一半 */
  margin-right: 20px; /* 设置元素间距 */
}

.knowname > div:last-child {
  margin-right: 0; /* 最后一个元素不需要右边距 */
}
.knowname-item {
  display: flex; /* 将元素设置为行内元素 */
  align-items: center; /* 垂直居中对齐 */
  margin-right: 20px; /* 可根据需要调整间距 */
}
.knowname-item {
  display: flex;
  align-items: flex-start; /* 顶部对齐 */
  margin-bottom: 20px; /* 控制组件之间的下间距 */
}

.knowname-item:last-child {
  margin-bottom: 0; /* 最后一个元素不需要下间距 */
}
.label {
  font-size: 16px; /* 设置较大的字体大小 */
  display: inline-block;
  vertical-align: bottom;
}

.description {
  font-size: 14px !important; /* 设置较小的字体大小 */
  color: #999; /* 设置浅色字体颜色 */
  display: inline-block;
  vertical-align: bottom;
}



.knowname{
  display: flex;
  justify-content: flex-start;
}
.knowname1, .knowname2, .knowname3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.knowname1 .el-input-number, .knowname2 .el-input-number, .knowname3 .el-radio {
  margin-top: 10px; /* 调整 el-select 与 span 之间的间距 */
}
.allname {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.name1, .name2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name1 .el-select, .name2 .el-select {
  margin-top: 10px; /* 调整 el-select 与 span 之间的间距 */
}
.fileDeal{
  float: left;
}
.righttitle {
  margin-top: 33px;
  margin-left: 612px;
  width: 214px;
  height: 43px;
  background: #59C8FF;
  border-radius: 21px;
  font-family: Microsoft YaHei UI;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}
.righhtquestion {
  margin-top: 37px;
  margin-left: 120px;
  display: flex;

  img {
    width: 44px;
    height: 44px;
  }

  div {
    margin-left: 28px;
    //min-height: 85px; /* 使用 min-height 以适应内容的高度 */
    width: 1003px;
    background: #FFFFFF;
    border-radius: 20px;

    div {
      margin-left: 53px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      font-size: 22px;
      line-height: 85px;
      text-align: left;
      color: #4C4C4C;
    }
  }
}

.rightanswer {
  margin-top: 13px;
  margin-left: 116px;
  display: flex;

  img {
    width: 47px;
    height: 44px;
  }

  .rightanswercontent {
    margin-left: 31px;
    //min-height: 321px; /* 使用 min-height 以适应内容的高度 */
    width: 1056px;
    background: #FFFFFF;
    border-radius: 20px;
  }
}
.select {
  margin-top: 187px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .selectdiv1 {
    margin-top: 60px;
    margin-left: 65px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  img {
    margin-top: 59px;
    margin-left: 155px;
    width: 40px;
    height: 31px;
  }

  .selectdiv2 {
    margin-top: 28px;
    margin-left: 31px;
    width: 192px;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px;
    border: 1px solid #96AFFF;
    text-align: center;
    a{
      font-family: Microsoft YaHei UI;
      font-weight: 550;
      font-size: 28px;
      color: #2070CA;
      line-height: 92px;
      text-decoration: none;
    }

  }
}

.hand {
  margin-top: 51px;
  margin-left: 307px;
  width: 823px;
  height: 147px;
  background: #FFFFFF;
  border-radius: 20px;
  display: flex;

  .handdiv1 {
    margin-left: 68px;
    margin-top: 60px;
    height: 28px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    font-size: 28px;
    color: #4C4C4C;
  }

  .handdiv2 {
    margin-top: 65px;
    margin-left: 237px;
    height: 21px;
    font-family: Microsoft YaHei UI;
    font-weight: 300;
    font-size: 22px;
    color: #2070CA;
  }
}
.right {
  overflow-y: auto; /* Add vertical scroll if content overflows */
  max-height: 1065px; /* Limit height of the container */
}

.righttext {
  padding: 20px; /* Add some padding to the content */
}

.righttext > div {
  margin-bottom: 15px; /* Add spacing between child div elements */
}

.nameList {
  margin-top: 20px; /* Add spacing between the table and other content */
}

.upload-demo {
  margin-bottom: 15px; /* Add spacing below the upload component */
}
.upload-header3 {
  margin-bottom: 20px; /* 可以根据需要调整间距大小 */
}
.upload-header4 {
  margin-top: 40px; /* 可以根据需要调整间距大小 */
}

</style>
<script>
import axios from "axios";
let mymessage = {}
export default {
  name: "selectionAssistant_2",
  created() {
    mymessage = {
      info: (options, single = true) => {
        this.$message({message: options, type: 'info'})
      },
      warning: (options, single = true) => {
        this.$message({message: options, type: 'warning'})
      },
      error: (options, single = true) => {
        this.$message({message: options, type: 'error'})
      },
      success: (options, single = true) => {
        this.$message({message: options, type: 'success'})
      },
    }
    this.getlist()
  },
  data(){
    return{
      kbInfo: '', // 知识库介绍
      questionInput: "", // 用户输入的问题
      textContent:{},
      loading: false, // 如果需要加载状态，可以根据实际情况添加
      questionsWithAnswers: [], // 保存用户提问及接口返回的答案
      nameList:[],
      selectList:[],
      selectedItem: '', // 用于存储选择的值
      contentList:[],
      num: 1,
      radio: '',
      knowledgeBaseName: '', // 新知识库名称
      selectedOption: null, // 选中的向量库类型选项
      selectedOption1: null, // 选中的 Embedding 模型选项
      options: [{ value: '选项1', label: 'faiss' }],
      options1: [{ value: '选项1', label: 'bge-large-zh' }],
      temperature: 0.7, // 初始值可根据需求修改
      knowledgematchnum: 3, // 初始值可根据需求修改
      knowledgematchmax: 0.7, // 初始值可根据需求修改
      fileName: '', // 存储上传文件名
      knowledge_base_name: '', // 存储选择的知识库名称
      uploadFileList: [],
      uploadURL: 'http://10.99.16.24:8088/medical/',
      uploadURLData:{method:'upload_file',createTime:''},
      fileLinks: {}, // 存储文件名到链接的映射
      fileCon:'',
    }
  },
  methods:{
    handleChange(field) {
      // 根据传入的字段名更新对应的变量值
      switch (field) {
        case 'temperature_size':
          this.temperature = value;
          break;
        case 'knowledge_match_num':
          this.knowledgematchnum = value;
          break;
        case 'knowledge_match_max':
          this.knowledgematchmax = value;
          break;
        default:
          break;
      }
    },
    add() {
      localStorage.clear()
      localStorage.setItem('temperature_size',JSON.stringify(this.temperature))

      localStorage.setItem('knowledge_match_num',JSON.stringify(this.knowledgematchnum))

      localStorage.setItem('knowledge_match_max',JSON.stringify(this.knowledgematchmax))
      this.$router.push('/recommended_1');
    },
    junk() {
      this.$router.push('/recommended_1');
    },
  }
};
</script>