<template>
    <div class="body">
        <div class="modal" @click="changePages">
            <div v-if="modal_register == 1">
                <div class="tipMsg">为了更好的帮助您，希望您能完善一些信息。</div>
                <div class="RegMsg">
                    <span>任职单位</span>
                </div>
                <div class="schMsg">
                    <span @click="enableEditing">{{ school }}</span>
                    <div class="inputschool">
                    </div>
                </div>
            </div>
            <div v-if="modal_register == 2">
                <div class="tipMsg">为了更好的帮助您，希望您能完善一些信息。</div>
                <div class="RegMsg">
                    <span>教育背景</span>
                </div>
                <div class="schMsg">
                    <span @click="enableEditing">{{ school }}</span>
                    <div class="inputschool">
                    </div>
                </div>
            </div>
            <div v-if="modal_register == 3">
                <div class="tipMsg">为了更好的帮助您，希望您能完善一些信息。</div>
                <div class="RegMsg">
                    <span>研究方向</span>
                </div>
                <div class="schMsg">
                    <span @click="enableEditing">{{ researchDirection }}</span>
                    <div class="inputschool">
                    </div>
                </div>
            </div>
            <div v-if="modal_register == 4">
                <div class="ReadyMsg">现在，使用长江文化大模型获取您的投稿帮助吧！</div>
                <div class="RegMsg_1">
                    <span>点击进入</span>
                </div>
            </div>
        </div>
    </div>

</template>

<style src="./register.css" scoped></style>
<style lang="less" scoped></style>
<script>
export default {
    data() {
        return {
            researchDirection: "长江文化 文学",
            school: "学校/机构//",
            modal_register: 1,
            step: 1,
        }
    },
    methods: {
        changePages() {
            this.modal_register++;
            this.modal_register = this.modal_register % 5
        },
        enableEditing() {
        },
        saveSchool() {
            this.modal_register = 2;
        }
    },

}


</script>
